<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        {{
          inputs.language === 'en'
            ? 'How many lone pairs would be found in the structure below (assuming it is a neutral molecule)?'
            : 'Combien de paires d’électrons non-liants se retrouve dans la structure suivante ?'
        }}
      </p>

      <p class="pl-8 mb-4">
        <v-img style="max-width: 240px" :src="imageName" />
      </p>

      <p class="mb-0">
        Paires d’électrons non-liants:
        <v-select
          v-model="inputs.numLonePairs"
          class="d-sm-inline-block ml-3 mr-3"
          style="width: 120px"
          dense
          :items="items"
          placeholder="Nombre:"
        />
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'Question497',
  components: {CalculationInput, ChemicalLatex, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        numLonePairs: null,
      },
      items: [
        {text: '1', value: '1'},
        {text: '2', value: '2'},
        {text: '3', value: '3'},
        {text: '4', value: '4'},
        {text: '5', value: '5'},
        {text: '6', value: '6'},
        {text: '7', value: '7'},
        {text: '8', value: '8'},
        {text: '9', value: '9'},
      ],
    };
  },
  computed: {
    imageName() {
      return '/img/assignments/determiningNumLonePairs.png';
    },
  },
};
</script>
<style scoped></style>
